html {
  color: #ffffff;
  font-size: 1em;
  line-height: 1.5;
  height: 100%;
}
div {
  box-sizing: border-box;
}
body {
  font-family: "Futura PT Cond", sans-serif;
  height: 100%;
  background: linear-gradient(180deg, #3e5a94 0%, #38346e 100%) no-repeat fixed;
}

@font-face {
  font-family: "Futura PT Cond";
  src: url("../assets/FuturaPTCond-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Futura PT Cond";
  src: url("../assets/FuturaPTCond-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
/* ============= TYPOGRAPHY ============== */
h1 {
  font-weight: 700;
  font-size: 2.9rem;
  /*line-height: 2.85rem;*/
  line-height: 99%;
  text-transform: uppercase;
}
h2 {
  font-weight: 700;
  font-size: 2.5rem;
  /*line-height: 3.5rem;*/
  line-height: 140%;
  text-transform: uppercase;
}
h3 {
  font-weight: 700;
  font-size: 1.7rem;
  /*line-height: 3.5rem;*/
  line-height: 140%;
  text-transform: uppercase;
}
.text, .text ul {
  font-size: 1.5rem;
  /*line-height: 1.45rem;*/
  line-height: 99%;
}
.small-text,
ul {
  font-size: 1.5rem;
  line-height: 99%;
}
.label {
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 130%;
}
.label span {
  font-size: 1.5rem;
  text-transform: none;
}
a {
  font-size: 1.75rem;
}
.hidden-mobile {
  display: none;
}
.hidden-desktop {
  display: block;
}
@media only screen and (min-width: 768px) and (min-height: 400px) {
  h1 {
    font-size: 5.55rem;
    /*line-height: 5.5rem;*/
  }
  h2 {
    font-size: 4.6rem;
    /*line-height: 7.28rem;*/
  }
  h3 {
    font-size: 2.0rem;
    /*line-height: 7.28rem;*/
  }
  .text {
    font-size: 2.5rem;
    /*line-height: 2.45rem;*/
  }
  .text, .text ul {
    font-size: 2.5rem;
    /*line-height: 2.45rem;*/
  }
  .small-text,
  ul {
    font-size: 1.8rem;
  }
  .label {
    font-size: 2.25rem;
  }
  .label span {
    font-size: 3.125rem;
  }
  a {
    font-size: 2.75rem;
  }
  .hidden-mobile {
    display: block;
  }
  .hidden-desktop {
    display: none;
  }
}

label {
  font-weight: 700;
  text-transform: uppercase;
}

/* =============== COMPONENTS ================== */
/* BUTTON */
a.button {
  border-radius: 10px;
  padding: 16px 26px;
  text-transform: uppercase;
  color: #e7e3e4;
  font-weight: 700;
  font-size: 28px;
  line-height: 100%;
  cursor: pointer;
  border: solid 4px #ffffff;
  background: transparent;
  text-decoration: none;
  display: inline-block;
  text-align: center;
}
a.button:hover {
  text-decoration: none;
  background-color: #e95c49;
  border-color: #e95c49;
  color: white;
}
a.button:visited:hover {
  text-decoration: underline;
}
.button.accent {
  background: #6b7f3a;
  border-color: #6b7f3a;
}
/* LINK */
a {
  color: white;
  text-transform: none;
  text-decoration: none;
}
a:hover {
  /*color: #e95c49;*/
  text-decoration: underline;
}
a:visited {
  color: white;
  text-transform: none;
  text-decoration: none;
}
a:visited:hover {
  /*color: #e95c49;*/
  text-decoration: underline;
}
a.active {
  color: #e95c49;
}
/* ACCORDION */
.tabs {
  overflow: hidden;
}
.tab {
  width: 100%;
  overflow: hidden;
}
.tab-label {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}
.tab-label::after {
  content: "\276F";
  min-width: 1em;
  min-height: 1em;
  text-align: center;
  transition: all 0.35s;
  transform: rotate(90deg);
}
.tab-content {
  max-height: 0;
  transition: all 0.35s;
}
.tab input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.tab input:checked + .tab-label::after {
  transform: rotate(-90deg);
}
.tab input:checked ~ .tab-content {
  max-height: 1300px;
}
/* HORIZONTAL LINE */
hr {
  display: block;
  height: 1px;
  border: 0;
  background: linear-gradient(to right, #ffffff, #38346e);
  margin: 1em 0;
  padding: 0;
}
hr.side-margin {
  width: 90%;
  margin: 0 auto;
}
/* ================= SECTIONS STYLES ====================== */
/* MAIN */
.main .background {
  background: url("../assets/train_bg_mobile.png")
    center / cover;
  position: absolute;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
}
.main .content {
  margin: 20px;
  z-index: 1;
  position: relative;
  height: 100%;
  /* .main .content { */
    /* margin: 40px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* } */
}
.logo {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo img {
  width: 75px;
}
.languages > a {
  margin-left: 20px;
}
.main .title {
  /* margin-top: 50px; */
}
.main .title p {
  /* margin: 0 0 85px; */
}
.main .title h1 {
  margin-bottom: 30px;
}
.main .title h1 span {
  color: #e95c49;
}
.actions {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.main {
  height: 900px;
  /* margin-top: -40px; */
}
.actions {
  /* flex-direction: row; */
  /* gap: 40px; */
  margin-top: auto;
  /* margin-bottom: 40px; */
}
/* todo fix iphone horizontal 100vh */
@media only screen and (min-width: 768px) and (min-height: 400px) {
  .main {
    height: 100vh;
    margin-top: -40px;
  }
}

@media only screen and (min-width: 768px) {
  .main .background {
    background: url("../assets/train_bg.png") center /
      cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
  }
  .logo img {
    width: 143px;
  }
  .logo {
    /* margin-top: 40px; */
  }
  .main .title {
    margin-top: 20px;
    /* max-width: 750px; */
  }
  .main .title h1 {
    margin-top: 40px;
  }
  .main .content {
    margin: 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .main .title p {
    margin-bottom: 40px;
  }
  .actions {
    flex-direction: row;
    gap: 40px;
    margin-top: auto;
    margin-bottom: 40px;
  }
}
/*@media only screen and (min-width: 1100px) {*/
/*  .main .title {*/
/*    width: 70%;*/
/*  }*/
/*}*/
/*@media only screen and (min-width: 1400px) {*/
/*  .main .title {*/
/*    width: 50%;*/
/*  }*/
/*}*/
@media only screen and (min-height: 766px) {
  .main .title {
    /* margin-top: 100px; */
  }
  .main .title p {
    /* margin-bottom: 80px; */
  }
}
@media only screen and (min-height: 776px) {
  .main .title {
    /* margin-top: 160px; */
  }
  .main .title p {
    margin-bottom: 80px;
  }
}
@media only screen and (min-height: 1176px) {
  .main .title {
    margin-top: 260px;
  }
  .main .title p {
    margin-bottom: 80px;
  }
}

/* DESCRIPTION */
.description {
  padding: 0 20px;
}
.description img.mobile {
  width: 100%;
}
.description .text {
  margin: 70px 0 50px;
}
.description .desktop {
  max-width: 52%;
}
.description .actions {
  margin-bottom: 40px;
}
@media only screen and (min-width: 768px) {
  .description {
    display: flex;
    align-items: center;
    padding: 40px 40px 0 0;
    flex-direction: row-reverse;
  }
  .description .text {
    margin-top: 200px;
  }
  .description .actions {
    /* justify-content: center; */
  }
}
/* BUY TICKET */
.buy-ticket {
  text-align: center;
}
.buy-ticket .content {
  padding: 25px 20px;
}
.buy-ticket .actions {
  justify-content: center;
  margin: 40px 20px;
}
.buy-ticket img {
  max-width: 80%;
}
@media only screen and (min-width: 768px) {
  .buy-ticket .content {
    padding: 0 40px;
  }
  .buy-ticket h2 {
    margin-bottom: 20px;
    margin-top: 130px;
  }
  .buy-ticket .actions {
    margin: 0;
  }
}
/* PROGRESS */
.progress {
  margin: 100px 0;
  padding: 0 20px;
}
.train {
  display: flex;
  gap: 5px;
  justify-content: center;
}
.car-full {
  background: url("../assets/train_full.png") no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.car {
  background: url("../assets/train_empty.png") no-repeat;
  background-size: contain;
  position: relative;
  flex: 1;
  padding-top: 15.3%;
}
.car.last .car-full {
  right: 90%;
}
.progress .grid-container {
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  grid-template-columns: repeat(2, 1fr);
}
.progress .current {
  grid-row: 1 / 1;
  grid-column: 1 / 3;
}
.train {
  grid-area: 2 / 1 / 2 / 3;
  margin: 30px 0;
}
.progress .target {
  grid-row: 3 / 3;
  grid-column: 2 / 2;
  display: flex;
  justify-content: end;
}
.progress p {
  margin: 0;
  line-height: 170%;
}
@media only screen and (min-width: 768px) {
  .progress {
    margin-top: 85px;
    padding: 0 90px;
  }
  .progress .grid-container {
    grid-template-rows: auto 1fr;
  }
  .progress .current {
    grid-row: 3 / 3;
    grid-column: 1 / 3;
  }
  .train {
    margin: 0 0 70px 0;
  }
}
/* BANKA CONTENT */
.banka-content .title {
  margin: 0 20px;
}
.banka-content h2 {
  margin-bottom: 0;
}
.banka-content .text {
  max-width: 500px;
  margin: 0 20px;
}
.banka-content img {
  margin-right: -80px;
  width: 100%;
}
.banka-content .title {
  max-width: 80%;
}
.banka-content .actions {
  /* max-width: 80%; */
  justify-content: center;
  margin: 40px 20px;
}
@media only screen and (min-width: 768px) {
  .banka-content .title {
    text-align: center;
    margin: 220px auto 0;
  }
  .banka {
    display: flex;
    gap: 100px;
    margin: 90px 40px 0;
    justify-content: center;
  }
}
/* AID CONTENT */
.aid-content .title {
  margin: 0 20px;
}
.aid-content img {
  margin-right: -80px;
  width: 100%;
}
.ifak {
  margin: 0 20px;
}
.ifak > div {
  flex: 1;
}
.ifak .actions {
  margin: 60px 0 50px;
}
.ifak .image {
  margin: 0 -20px;
}
.aid-content .title {
  max-width: 80%;
}
@media only screen and (min-width: 768px) {
  .aid-content .title {
    text-align: center;
    margin: 220px auto 0;
  }
  .ifak {
    display: flex;
    gap: 80px;
    margin: 90px 40px 0;
  }
}
/* FAQ */
ul {
  padding-inline: 25px 0;
  margin-block-start: 0;
  padding-block-start: 1em;
}
.tab li:not(:last-child) {
  margin-bottom: 27px;
}
.tabs {
  margin: 70px 20px 95px;
}
.tabs .tab-content p {
  margin: 0;
  padding: 20px 0;
}
.faq hr {
  margin: 30px 0;
}
@media only screen and (min-width: 768px) {
  .tabs {
    margin: 90px 40px;
  }
}
/* HOW SUPPORT */
.how-support {
  text-align: center;
  margin-bottom: 50px;
}
.how-support .actions {
  margin: 0 20px;
}
@media only screen and (min-width: 768px) {
  .how-support {
    margin-top: 150px;
  }
  .how-support .title {
    margin: 0 auto;
  }
  .how-support .actions {
    justify-content: center;
  }
}
/* FOOTER */
.footer {
  text-align: center;
  width: 90%;
  display: flex;
  flex-direction: column;
  margin: 50px auto 0;
}
.footer > div {
  flex: 1;
}
.footer p {
  margin-top: 0;
}
.reports {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  .footer {
    flex-direction: row;
    justify-content: space-between;
    text-align: start;
  }
  .reports {
    text-align: end;
    display: block;
  }
}

/* HELPERS */
@media only screen and (min-width: 768px) {
  .desktop {
    display: initial;
  }
  .mobile {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: initial;
  }
}
/* ==================================================================== */
/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * Vendor-prefixed and regular ::selection selectors cannot be combined:
 * https://stackoverflow.com/a/16982510/7133471
 *
 * Customize the background color to match your design.
 */

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   https://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

@media print {
  *,
  *::before,
  *::after {
    background: #fff !important;
    color: #000 !important;
    /* Black prints faster */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: " (" attr(href) ")";
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */
  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /*
   * Printing Tables:
   * https://web.archive.org/web/20180815150934/http://css-discuss.incutio.com/wiki/Printing_Tables
   */
  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}
