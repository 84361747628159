html {
  color: #fff;
  height: 100%;
  font-size: 1em;
  line-height: 1.5;
}

div {
  box-sizing: border-box;
}

body {
  height: 100%;
  background: linear-gradient(#3e5a94 0%, #38346e 100%) no-repeat fixed;
  font-family: Futura PT Cond, sans-serif;
}

@font-face {
  font-family: Futura PT Cond;
  src: url("FuturaPTCond-Book.7c72d3cc.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Futura PT Cond;
  src: url("FuturaPTCond-Bold.95759b9d.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

h1 {
  text-transform: uppercase;
  font-size: 2.9rem;
  font-weight: 700;
  line-height: 99%;
}

h2 {
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 140%;
}

h3 {
  text-transform: uppercase;
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 140%;
}

.text, .text ul, .small-text, ul {
  font-size: 1.5rem;
  line-height: 99%;
}

.label {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  line-height: 130%;
}

.label span {
  text-transform: none;
  font-size: 1.5rem;
}

a {
  font-size: 1.75rem;
}

.hidden-mobile {
  display: none;
}

.hidden-desktop {
  display: block;
}

@media only screen and (min-width: 768px) and (min-height: 400px) {
  h1 {
    font-size: 5.55rem;
  }

  h2 {
    font-size: 4.6rem;
  }

  h3 {
    font-size: 2rem;
  }

  .text, .text, .text ul {
    font-size: 2.5rem;
  }

  .small-text, ul {
    font-size: 1.8rem;
  }

  .label {
    font-size: 2.25rem;
  }

  .label span {
    font-size: 3.125rem;
  }

  a {
    font-size: 2.75rem;
  }

  .hidden-mobile {
    display: block;
  }

  .hidden-desktop {
    display: none;
  }
}

label {
  text-transform: uppercase;
  font-weight: 700;
}

a.button {
  text-transform: uppercase;
  color: #e7e3e4;
  cursor: pointer;
  text-align: center;
  background: none;
  border: 4px solid #fff;
  border-radius: 10px;
  padding: 16px 26px;
  font-size: 28px;
  font-weight: 700;
  line-height: 100%;
  text-decoration: none;
  display: inline-block;
}

a.button:hover {
  color: #fff;
  background-color: #e95c49;
  border-color: #e95c49;
  text-decoration: none;
}

a.button:visited:hover {
  text-decoration: underline;
}

.button.accent {
  background: #6b7f3a;
  border-color: #6b7f3a;
}

a {
  color: #fff;
  text-transform: none;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:visited {
  color: #fff;
  text-transform: none;
  text-decoration: none;
}

a:visited:hover {
  text-decoration: underline;
}

a.active {
  color: #e95c49;
}

.tabs {
  overflow: hidden;
}

.tab {
  width: 100%;
  overflow: hidden;
}

.tab-label {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.tab-label:after {
  content: "❯";
  min-width: 1em;
  min-height: 1em;
  text-align: center;
  transition: all .35s;
  transform: rotate(90deg);
}

.tab-content {
  max-height: 0;
  transition: all .35s;
}

.tab input {
  opacity: 0;
  z-index: -1;
  position: absolute;
}

.tab input:checked + .tab-label:after {
  transform: rotate(-90deg);
}

.tab input:checked ~ .tab-content {
  max-height: 1300px;
}

hr {
  height: 1px;
  background: linear-gradient(to right, #fff, #38346e);
  border: 0;
  margin: 1em 0;
  padding: 0;
  display: block;
}

hr.side-margin {
  width: 90%;
  margin: 0 auto;
}

.main .background {
  height: 100%;
  background: url("train_bg_mobile.322b7e16.png") center / cover;
  position: absolute;
  inset: 100px 0 0;
}

.main .content {
  z-index: 1;
  height: 100%;
  height: 100%;
  flex-direction: column;
  margin: 20px;
  display: flex;
  position: relative;
}

.logo {
  justify-content: space-between;
  align-items: center;
  margin-top: 36px;
  display: flex;
}

.logo img {
  width: 75px;
}

.languages > a {
  margin-left: 20px;
}

.main .title h1 {
  margin-bottom: 30px;
}

.main .title h1 span {
  color: #e95c49;
}

.actions {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.main {
  height: 900px;
}

.actions {
  margin-top: auto;
}

@media only screen and (min-width: 768px) and (min-height: 400px) {
  .main {
    height: 100vh;
    margin-top: -40px;
  }
}

@media only screen and (min-width: 768px) {
  .main .background {
    height: 100%;
    background: url("train_bg.245cf20e.png") center / cover;
    position: absolute;
    inset: 0;
  }

  .logo img {
    width: 143px;
  }

  .main .title {
    margin-top: 20px;
  }

  .main .title h1 {
    margin-top: 40px;
  }

  .main .content {
    height: 100%;
    flex-direction: column;
    margin: 40px;
    display: flex;
  }

  .main .title p {
    margin-bottom: 40px;
  }

  .actions {
    flex-direction: row;
    gap: 40px;
    margin-top: auto;
    margin-bottom: 40px;
  }
}

@media only screen and (min-height: 776px) {
  .main .title p {
    margin-bottom: 80px;
  }
}

@media only screen and (min-height: 1176px) {
  .main .title {
    margin-top: 260px;
  }

  .main .title p {
    margin-bottom: 80px;
  }
}

.description {
  padding: 0 20px;
}

.description img.mobile {
  width: 100%;
}

.description .text {
  margin: 70px 0 50px;
}

.description .desktop {
  max-width: 52%;
}

.description .actions {
  margin-bottom: 40px;
}

@media only screen and (min-width: 768px) {
  .description {
    flex-direction: row-reverse;
    align-items: center;
    padding: 40px 40px 0 0;
    display: flex;
  }

  .description .text {
    margin-top: 200px;
  }
}

.buy-ticket {
  text-align: center;
}

.buy-ticket .content {
  padding: 25px 20px;
}

.buy-ticket .actions {
  justify-content: center;
  margin: 40px 20px;
}

.buy-ticket img {
  max-width: 80%;
}

@media only screen and (min-width: 768px) {
  .buy-ticket .content {
    padding: 0 40px;
  }

  .buy-ticket h2 {
    margin-top: 130px;
    margin-bottom: 20px;
  }

  .buy-ticket .actions {
    margin: 0;
  }
}

.progress {
  margin: 100px 0;
  padding: 0 20px;
}

.train {
  justify-content: center;
  gap: 5px;
  display: flex;
}

.car-full {
  background: url("train_full.657e7ef5.png") 0 0 / cover no-repeat;
  position: absolute;
  inset: 0;
}

.car {
  background: url("train_empty.971037bc.png") 0 0 / contain no-repeat;
  flex: 1;
  padding-top: 15.3%;
  position: relative;
}

.car.last .car-full {
  right: 90%;
}

.progress .grid-container {
  grid-template-rows: 1fr auto 1fr;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.progress .current {
  grid-area: 1 / 1 / 1 / 3;
}

.train {
  grid-area: 2 / 1 / 2 / 3;
  margin: 30px 0;
}

.progress .target {
  grid-area: 3 / 2 / 3 / 2;
  justify-content: end;
  display: flex;
}

.progress p {
  margin: 0;
  line-height: 170%;
}

@media only screen and (min-width: 768px) {
  .progress {
    margin-top: 85px;
    padding: 0 90px;
  }

  .progress .grid-container {
    grid-template-rows: auto 1fr;
  }

  .progress .current {
    grid-area: 3 / 1 / 3 / 3;
  }

  .train {
    margin: 0 0 70px;
  }
}

.banka-content .title {
  margin: 0 20px;
}

.banka-content h2 {
  margin-bottom: 0;
}

.banka-content .text {
  max-width: 500px;
  margin: 0 20px;
}

.banka-content img {
  width: 100%;
  margin-right: -80px;
}

.banka-content .title {
  max-width: 80%;
}

.banka-content .actions {
  justify-content: center;
  margin: 40px 20px;
}

@media only screen and (min-width: 768px) {
  .banka-content .title {
    text-align: center;
    margin: 220px auto 0;
  }

  .banka {
    justify-content: center;
    gap: 100px;
    margin: 90px 40px 0;
    display: flex;
  }
}

.aid-content .title {
  margin: 0 20px;
}

.aid-content img {
  width: 100%;
  margin-right: -80px;
}

.ifak {
  margin: 0 20px;
}

.ifak > div {
  flex: 1;
}

.ifak .actions {
  margin: 60px 0 50px;
}

.ifak .image {
  margin: 0 -20px;
}

.aid-content .title {
  max-width: 80%;
}

@media only screen and (min-width: 768px) {
  .aid-content .title {
    text-align: center;
    margin: 220px auto 0;
  }

  .ifak {
    gap: 80px;
    margin: 90px 40px 0;
    display: flex;
  }
}

ul {
  margin-block-start: 0;
  padding-block-start: 1em;
  padding-inline: 25px 0;
}

.tab li:not(:last-child) {
  margin-bottom: 27px;
}

.tabs {
  margin: 70px 20px 95px;
}

.tabs .tab-content p {
  margin: 0;
  padding: 20px 0;
}

.faq hr {
  margin: 30px 0;
}

@media only screen and (min-width: 768px) {
  .tabs {
    margin: 90px 40px;
  }
}

.how-support {
  text-align: center;
  margin-bottom: 50px;
}

.how-support .actions {
  margin: 0 20px;
}

@media only screen and (min-width: 768px) {
  .how-support {
    margin-top: 150px;
  }

  .how-support .title {
    margin: 0 auto;
  }

  .how-support .actions {
    justify-content: center;
  }
}

.footer {
  text-align: center;
  width: 90%;
  flex-direction: column;
  margin: 50px auto 0;
  display: flex;
}

.footer > div {
  flex: 1;
}

.footer p {
  margin-top: 0;
}

.reports {
  flex-direction: column;
  margin-bottom: 50px;
  display: flex;
}

@media only screen and (min-width: 768px) {
  .footer {
    text-align: start;
    flex-direction: row;
    justify-content: space-between;
  }

  .reports {
    text-align: end;
    display: block;
  }

  .desktop {
    display: initial;
  }

  .mobile {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: initial;
  }
}

::selection {
  text-shadow: none;
  background: #b3d4fc;
}

@media print {
  *, :before, :after {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: #fff !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }
}

/*# sourceMappingURL=index.781a6585.css.map */
